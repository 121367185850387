<template>
  <b-card class="product-detail">
    <b-row>
      <b-col lg="6" v-if="prodInfo.featured_image">
        <Swiper
          class="detail__images h-100 position-relative overflow-hidden"
          :options="swiperOptions"
          :pagination="true"
        >
          <swiperSlide
            v-for="(image, key) in [
              prodInfo.featured_image,
              ...prodInfo.images.map((image) => image.url),
            ]"
            :key="key"
          >
            <section
              :style="{
                'background-size': 'cover',
                'background-position': 'center ',
                'background-image': 'url(' + image + ')',
                backgroundRepeat: 'no-repeat',
              }"
              class="slide-bg w-100 h-100 position-absolute"
            ></section>
          </swiperSlide>
        </Swiper>
      </b-col>
      <b-col lg="6">
        <div class="d-flex flex-column justify-content-between h-100">
          <div class="product-item-title mb-2">
            <div>
              <h2>{{ prodInfo.title }}</h2>
              <div class="product-item-description">
                <h6>
                  {{
                    prodInfo.description.length >= 130
                      ? prodInfo.description.slice(0, 130) + "...."
                      : prodInfo.description
                  }}
                </h6>

                <p class="product-item-price text-primary font-size-30 m-0 p-0">
                  {{ prodInfo.price }} {{ $t("main.sar") }}
                  <span class="d-block font-size-14"
                    >{{ $t("main.per") }} {{ prodInfo.unit }}
                  </span>
                </p>
              </div>
            </div>
            <div class="d-flex direction-column justify-content-start h-100">
              <b-button
                v-if="!checkFavourite(prodInfo)"
                @click="addToFavourite(prodInfo)"
                class="d-flex justify-content-between align-items-center py-2 product-card-heart-button"
              >
                <i class="las la-heart font-size-24"></i>
              </b-button>
              <b-button
                v-else
                @click="deleteProductFavourite(prodInfo)"
                class="d-flex justify-content-between align-items-center py-2 product-in-favourite-button"
              >
                <i class="las la-heart font-size-24"></i>
              </b-button>
            </div>
          </div>
          <div>
            <div
              class="product-item-offer-info d-flex justify-content-between align-items-center mt-0 mb-3"
            ></div>
            <b-col
              cols="12"
              class="product-item-calculate d-flex justify-content-between align-items-center"
            >
              <b-button
                @click="increaseQuantity"
                class="bg-white m-0 p-0 text-primary border-0"
                ><i class="las la-plus m-0 text-primary font-size-20"></i
              ></b-button>
              <span class="font-size-20 m-0 p-0 text-primary">{{
                quantity
              }}</span>
              <b-button
                @click="decreaseQuantity"
                class="bg-white m-0 p-0 text-primary border-0"
                ><i class="las la-minus m-0 text-primary font-size-20"></i
              ></b-button>
            </b-col>
            <b-row>
              <b-col cols="12">
                <add-cart-icon
                  class="w-100 mb-2 gap_1"
                  :product="prodInfo"
                  :quantity="quantity"
                >
                  <span class="font-size-17 text-white"
                    >{{ $t("marketplace.addToCart") }}
                  </span>
                </add-cart-icon>
                <b-button
                  variant="outline-primary"
                  class="d-flex justify-content-center align-items-center py-2 min-button"
                  @click="$router.push(`/stores/${prodInfo.store_id}/`)"
                >
                  <i class="las la-store font-size-26 mx-2"></i>
                  <span class="font-size-17 px-0">{{
                    $t("main.back_store")
                  }}</span>
                </b-button>
              </b-col>
            </b-row>
          </div>
        </div>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import sliderThumbs from "@/components/core/slider/sliderThumbs";
import productFunctions from "@/mixins/productFunctions";

import AppButton from "../../../../components/buttons/appButton.vue";
import AddCartIcon from "./profile/addCartIcon.vue";

export default {
  name: "productItemDescription",
  props: ["prodInfo"],
  components: { sliderThumbs, AppButton, AddCartIcon },
  mixins: [productFunctions],
  data() {
    return {
      quantity: 1,
      swiperOptions: {
        spaceBetween: 1,
        autoplay: false,
        slidesPerView: 1,
        pagination: {
          clickable: true,
          el: ".product-detail .swiper-pagination",
          type: "bullets",
        },
      },
    };
  },
  computed: {},
  methods: {
    goBackToStore() {
      this.$router.go(-1);
    },
    increaseQuantity() {
      this.quantity++;
    },
    decreaseQuantity() {
      if (this.quantity === 1) {
        this.quantity = 1;
      } else {
        this.quantity--;
      }
    },

    checkFavourite(product) {
      return this.$store.getters["cart/checkProductInFavourite"](product);
    },
  },
};
</script>

<style lang="scss" scoped>
.product-item-calculate {
  box-shadow: 0 0 12px #0000001c;
  border-radius: 4px;
  margin-bottom: 2.5rem;
  padding: 1rem;
  @media (max-width: 992px) {
    width: 100% !important;
    margin-bottom: 1rem;
  }
}

.product-item-price-info .product-item-offer {
  margin-left: 12px;
  color: #ec5d61;
  text-decoration: line-through #ec5d61;
}

.product-item-price-info .offer-price-percent {
  border-radius: 8px;
  background-color: #ec5d61;
  color: #ffffff;
}

.product-item-title {
  display: flex;
  justify-content: space-between;
  align-items: center;

  h2 {
    font-size: 2rem;
    color: #343a40;
    font-weight: 500;
  }
}

.min-button {
  width: 100%;
  margin-top: 10px !important;
  border-radius: 4px !important;
}

@media (max-width: 768px) {
  .product-item-calculate {
    margin: 0.75rem auto !important;
    // width: 100px !important;
  }
  .min-button {
    width: 100%;
    margin-top: 10px !important;
  }
  .return-btn {
    display: none !important;
  }
  .product-item-title {
    margin-top: 15px;
  }
  .offer-price-percent span {
    font-size: 12px !important;
  }
}

.product-detail {
  padding: 1rem;
  margin-top: 4rem;
  @media (max-width: 767px) {
    .detail__images {
      height: 300px !important;
    }
  }
}

.btn,
.btn i {
  margin: 0 !important;
}

.product-card-heart-button,
.product-in-favourite-button {
  border: none !important;
  background-color: #848484 !important;
  border-radius: 4px !important;
  box-shadow: 0 9px 20px #0000001a !important;
  width: 50px;
  height: 50px;
  margin: 0 !important;
}

.product-in-favourite-button {
  background-color: rgba(237, 237, 237, 0.78) !important;
}

.product-in-favourite-button i {
  color: #d62020;
  font-size: 1.7rem;
}

.product-item-description {
  margin: 0.625rem 0 1.875rem 0;
  h6 {
    color: #666666 !important;
    font-size: 1rem;
    font-weight: 400;
  }
}
</style>
