<template>
  <b-container v-if="!loadingProductInfo">
    <product-item-description
      :prodInfo="productInfo"
    ></product-item-description>

    <b-card v-if="productInfo.related_products" class="mt-5 mb-2 px-2">
      <template v-slot:header>
        <div class="d-flex justify-content-between align-items-center">
          <h2 class="py-1 text-primary font-weight-bold main-title-details">
            {{ $t("marketplace.mayYouLike") }}
          </h2>
          <b-button
            class="d-flex justify-content-between align-items-center bg-transparent border-0 see-more"
            @click="$router.push(`/stores/${productInfo.store_id}/`)"
          >
            <span class="main-see-more-detail text-primary ml-1">{{
              $t("marketplace.seeAll")
            }}</span>
            <i
              class="las text-primary font-size-26"
              :class="
                $i18n.locale === 'ar' ? 'la-caret-left' : 'la-caret-right'
              "
            ></i>
            <!--          <i class="las la-shopping-cart ml-0 mr-2 font-size-26 mr-5"></i>-->
          </b-button>
        </div>
      </template>
      <div class="position-relative">
        <div
          class="arrows arrow-category"
          v-if="productInfo.related_products.length > 4"
        >
          <span class="swiper-button-next top-40 position-absolute left-20">
            <div
              class="icon-category d-flex justify-content-center align-items-center"
            >
              <i class="las la-angle-left"></i>
            </div>
          </span>
        </div>
        <Swiper
          :id="`post-slider-list-of-stores`"
          :options="swiperOptions"
          :pagination="false"
        >
          <swiperSlide
            class=""
            v-for="(item, index) in productInfo.related_products"
            :key="index"
          >
            <router-link
              :to="{ name: 'productDetails', params: { productId: item.id } }"
            >
              <product-card :productItem="item"></product-card>
            </router-link>
          </swiperSlide>
        </Swiper>
      </div>
    </b-card>
  </b-container>
  <b-container
    v-else
    class="d-flex align-items-center justify-content-center mt-5"
  >
    <b-spinner large type="grow" variant="primary"></b-spinner>
  </b-container>
</template>
<script>
import productItemDescription from "@/modules/servicePages/marketplace/components/productItemDescription";
import productCard from "../components/productCard";
import marketPlace from "@/modules/servicePages/marketplace/services/marketplace";

export default {
  name: "productDetails",
  components: { productItemDescription, productCard },
  data() {
    return {
      rateDetails: [
        {
          id: 5,
          img: require("@/assets/images/ibbil/star.svg"),
          num: 76,
          value: 75,
        },
        {
          id: 4,
          img: require("@/assets/images/ibbil/star.svg"),
          num: 22,
          value: 55,
        },
        {
          id: 3,
          img: require("@/assets/images/ibbil/star.svg"),
          num: 12,
          value: 38,
        },
        {
          id: 2,
          img: require("@/assets/images/ibbil/star.svg"),
          num: 18,
          value: 45,
        },
        {
          id: 1,
          img: require("@/assets/images/ibbil/star.svg"),
          num: 7,
          value: 13,
        },
      ],
      clientComment: [
        {
          id: 0,
          commentName: "منتج جميل",
          commentDetails:
            "هناك حقيقة مثبتة منذ زمن طويل وهي أن المحتوى المقروء لصفحة ما سيلهي القارئ عن التركيز على الشكل الخارجي للنص أو شكل توضع الفقرات في الصفحة التي يقرأها.",
          commentDay: 7,
          commentDate: "مارس",
          commentYear: 2021,
          userComment: "Nawwaf",
        },
        {
          id: 1,
          commentName: "منتج رائع",
          commentDetails:
            "هناك حقيقة مثبتة منذ زمن طويل وهي أن المحتوى المقروء لصفحة ما سيلهي القارئ عن التركيز على الشكل الخارجي للنص أو شكل توضع الفقرات في الصفحة التي يقرأها.",
          commentDay: 22,
          commentDate: "أغسطس",
          commentYear: 2022,
          userComment: "Ahmed",
        },
      ],
      swiperOptions: {
        centeredSlides: false,
        spaceBetween: 50,
        autoplay: false,
        slidesPerView: 3,
        dir: "ltr",
        pagination: {
          clickable: true,
          el: ".swiper-pagination",
          type: "bullets",
        },
        loop: false,
        breakpoints: {
          480: {
            slidesPerView: 1,
            spaceBetween: 15,
            centeredSlides: true,
          },
          1200: {
            slidesPerView: 3,
            spaceBetween: 15,
            centeredSlides: false,
          },
          1400: {
            slidesPerView: 3,
            spaceBetween: 70,
            centeredSlides: false,
          },
        },
        // Navigation arrows
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },
      },
      productInfo: {},
      loadingProductInfo: true,
    };
  },

  methods: {
    getProductInfo() {
      this.loadingProductInfo = true;
      marketPlace
        .getProductDetail(this.$route.params.productId)
        .then((res) => {
          this.productInfo = res.data.data;
        })
        .finally(() => {
          this.loadingProductInfo = false;
        });
    },
  },
  created() {
    this.getProductInfo();
  },
};
</script>

<style lang="scss" scoped>
.product-item-details li {
  list-style-type: disc;
  padding-bottom: 10px;
}

.star-product img {
  width: 26px;
}

.star-product-comment {
  width: 20px;
}

.rate-header {
  width: 100%;
  background: #fbfbfb;
  border-radius: 8px;
}

.star-icon-rate {
  width: 17px;
  height: 16px;
}

.main-title-details {
  font-size: 1.5rem !important;
  color: #212529;
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
}

.main-see-more-detail {
  font-size: 20px !important;
}

.main-users-comment-order {
  font-size: 22px !important;
}

@media (max-width: 768px) {
  h2 {
    font-size: 22px !important;
  }
  .see-more i {
    font-size: 18px !important;
  }
  .product-item-details li {
    font-size: 16px !important;
  }
  .main-title-details {
    font-size: 20px !important;
  }
  .main-see-more-detail {
    font-size: 15px !important;
  }
  .main-users-comment-order {
    font-size: 18px !important;
  }
}

.iq-card {
  border-radius: 4px !important;
}

.card-header {
  border-bottom: 0 !important;
}

.swiper-slide {
  width: 270px;
}
</style>
